<template>
  <div v-if="homeInfo">
    <!-- 轮播 -->
    <div class="swiper">
      <el-carousel trigger="click" @change="swiperChange" :interval="5000" height="600px" arrow="always">
        <el-carousel-item v-for="item in homeInfo.carousel" :key="item.id">
          <img :src="item.images" />
        </el-carousel-item>
        <div class="indicator flex_center">
          <span
            v-for="(item, index) in homeInfo.carousel"
            :key="item.id"
            :style="index == swiperIndex ? 'background:#e50114' : ''"
          ></span>
        </div>
      </el-carousel>
    </div>
    <!-- 推荐赛事 -->
    <div class="match list" v-if="homeInfo.match.length != 0">
      <div class="content flex_direction_column">
        <div class="title flex_space_between">
          <img src="@/assets/image/img/02.png" />
          <span @click="moreClick(1)">more+</span>
        </div>
        <MatchList :list="homeInfo.match"></MatchList>
      </div>
    </div>
    <!-- 推荐活动 -->
    <div class="activity list" v-if="homeInfo.active.length != 0">
      <div class="content flex_direction_column">
        <div class="title flex_space_between">
          <img src="@/assets/image/img/06.png" />
          <span @click="moreClick(2)">more+</span>
        </div>
        <ActivityList :list="homeInfo.active"></ActivityList>
      </div>
    </div>
    <!-- 新闻速讯 -->
    <div class="news list" v-if="homeInfo.news.length != 0">
      <div class="content flex_direction_column">
        <div class="title flex_space_between">
          <img src="@/assets/image/img/07.png" />
          <span @click="moreClick(3)">more+</span>
        </div>
        <div class="news_list flex_space_between">
          <div class="news_list_left flex_direction_column">
            <div class="news_item flex" v-for="item in homeInfo.news" :key="item.id" @click="newsDetail(item.id)">
              <div class="news_item_left flex_direction_column">
                <span>{{ item.day }}</span>
                <p>{{ item.date }}</p>
              </div>
              <div class="news_item_right flex_direction_column">
                <span class="one_line">{{ item.title }}</span>
                <p class="one_line">
                  <!-- {{ item.subtitle }} -->
                </p>
              </div>
            </div>
            <!-- <div
              style="padding: 0 5px"
              class="list_item flex_space_between"
              v-for="item in homeInfo.news"
              :key="item.id"
              @click="newsDetail(item.id)"
            >
              <div class="list_item_left flex">
                <span></span>
                <p style="width: 250px" class="one-line">{{ item.title }}</p>
              </div>
              <div class="list_item_right">{{ item.date }}</div>
            </div> -->
          </div>
          <div class="news_list_right">
            <el-carousel height="340px" indicator-position="none">
              <el-carousel-item v-for="item in homeInfo.news_images" :key="item.id">
                <img class="img" :src="item.image" alt="" @click="newsDetail(item.id)" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading v-else></Loading>
</template>

<script>
import { getHome } from '@/service/api/index'
import MatchList from '@/components/list/match'
import ActivityList from '@/components/list/activity'
export default {
  components: { MatchList, ActivityList },
  data() {
    return {
      homeInfo: '',
      newsIndex: 0,
      swiperIndex: 0
    }
  },

  mounted() {
    this.getHome()
  },

  methods: {
    async getHome() {
      const res = await getHome()
      this.homeInfo = res.msg
    },
    swiperChange(index) {
      this.swiperIndex = index
    },

    newsDetail(id) {
      this.$router.push({
        path: '/news-detail',
        query: {
          id
        }
      })
    },
    moreClick(type) {
      if (type == 1) {
        this.$router.push('/match-list')
      } else if (type == 2) {
        this.$router.push('/activity-list')
      } else if (type == 3) {
        this.$router.push('/news-list')
      }
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
.list_item {
  line-height: 39px;
  height: 39px;
  font-size: 16px;
  width: 100%;
  padding: 0px 23px 0px 24px;
  cursor: pointer;
  &:hover {
    background: #fff9fa;
    .list_item_left {
      span {
        background: #e50114;
      }
      p {
        color: #e50114;
        // font-weight: bold;
      }
    }
    .list_item_right {
      color: #e50114;
    }
  }

  .list_item_left {
    span {
      width: 4px;
      height: 4px;
      background: rgba(214, 173, 110, 0.39);
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .list_item_right {
    color: #999999;
  }
}
.title {
  margin-top: 38px;
  margin-bottom: 43px;
  img {
    width: 330px;
    height: 40px;
  }
  span {
    width: 84px;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    color: @color_six;
    text-align: center;
    font-size: @font_size_16;
    background: @color_bg_two;
  }
}
.swiper {
  width: 100%;
  height: 600px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .indicator {
    z-index: 10;
    width: 100%;
    bottom: 33px;
    position: absolute;
    span {
      width: 19px;
      height: 4px;
      border-radius: 3px;
      margin-right: 10px;
      background: rgba(255, 255, 255);
    }
  }
  /deep/.el-carousel__arrow--left {
    width: 70px;
    height: 70px;
    left: 210px;
    font-size: 25px;
  }
  /deep/.el-carousel__arrow--right {
    width: 70px;
    height: 70px;
    right: 210px;
    font-size: 25px;
  }
  /deep/ .el-carousel__button {
    display: none;
  }
}
.list {
  padding: 0px 0 40px 0;
}
.match {
  background-color: #fff;
}
.activity {
  padding: 0px 0 40px 0;
  background-image: url('~@/assets/image/img/04.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.news {
  background-color: #fff;
  .news_list {
    align-items: flex-start;

    .news_list_left {
      flex: 1;
      margin-right: 30px;
      // width: 702px;
      align-items: flex-start;
      background-color: #fff;
      .news_item {
        height: 100px;
        cursor: pointer;
        margin-bottom: 20px;
        border-radius: 3px;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #e6425e !important;
        }
        .news_item_left {
          width: 100px;
          height: 100%;
          align-items: center;
          justify-content: center;
          background-color: #eeeeee;

          span {
            font-weight: bold;
            font-size: @font_size_30;
          }
          p {
            margin-top: 10px;
            font-size: @font_size_14;
          }
        }
        .news_item_right {
          &:hover {
            color: #e6425e !important;
            p {
              color: #e6425e !important;
            }
          }
          height: 100%;
          justify-content: center;
          align-items: flex-start;
          // width: 498px;
          width: 620px;
          padding-left: 20px;
          background-color: #fcfcfc;
          span {
            font-size: @font_size_18;
          }
          p {
            margin-top: 11px;
            color: @color_seven;
          }
        }
      }
    }
    .news_list_right {
      width: 630px;
      height: 400px;
      position: relative;
      /deep/ .el-carousel__item {
        border-radius: 3px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      .desc {
        left: 0;
        right: 0;
        bottom: 0;
        color: #fff;
        position: absolute;
        padding: 14px 24px;
        font-size: @font_size_16;
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
</style>
